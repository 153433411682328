const routes = [
    {
        path: '/company/approvers/supplier_contracts',
        name: 'CompanySystemSupplierContractApprovers',
        component: () => import('../../views/company/system_management/approvers/SupplierContractApprovers.vue')
    },
    {
        path: '/company/branches',
        name: 'CompanyBranches',
        component: () => import('../../views/company/system_management/branches/Branches.vue')
    },
    {
        path: '/company/branches/create',
        name: 'CompanyBranchesCreate',
        component: () => import('../../views/company/system_management/branches/CreateBranch.vue')
    },
]

export default routes;