import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/archive_management/jobs',
        name: 'QedArchiveManagementJobs',
        component: () => import('../../views/qed/archive_management/Jobs.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
]

export default routes;
