const routes = [
    {
      path: '/company/supplier-registration',
      name: 'CompanySupplierRegistration',
      component: () => import('../../views/company/supplier_registration/List.vue')
    },
    {
      path: '/company/edit/supplier-registration/:id',
      name: 'CompanySupplierRegistrationEdit',
      component: () => import('../../views/company/supplier_registration/Edit.vue')
    },
    {
      path: '/company/create/supplier-registration',
      name: 'CompanySupplierRegistrationCreate',
      component: () => import('../../views/company/supplier_registration/Create.vue')
    },
    {
      path: '/company/supplier-registration/details/:id',
      name: 'CompanySupplierRegistrationDetails',
      component: () => import('../../views/company/supplier_registration/Details.vue')
    },
    {
      path: '/company/supplier-registration/create/category/:id',
      name: 'CompanySupplierRegistrationCategoryCreate',
      component: () => import('../../views/company/supplier_registration/category/Create.vue')
    },
    {
      path: '/company/supplier-registration/edit/category/:supplier_registration_id/:category_id',
      name: 'CompanySupplierRegistrationCategoryEdit',
      component: () => import('../../views/company/supplier_registration/category/Edit.vue')
    },
    {
      path: '/company/supplier-registration/category/details/:supplier_registration_id/:category_id',
      name: 'CompanySupplierRegistrationCategoryDetails',
      component: () => import('../../views/company/supplier_registration/category/Details.vue')
    },
    {
      path: '/company/supplier-registration/duplicate/category/:supplier_registration_id/:category_id',
      name: 'CompanySupplierRegistrationDuplicateCategory',
      component: () => import('../../views/company/supplier_registration/CloneCategory.vue')
    },
    {
        path: '/company/supplier-registration/zip/files/progress/:task_id/:section_id',
        name: 'CompanySupplierRegistrationZipFiles',
        component: () => import('../../views/company/supplier_registration/category/ZipFilesProgress.vue')
    },
    {
      path: '/company/supplier-registration/cat/suppliers/progress/:supplier_registration_id/:task_id/',
      name: 'CompanySupplierRegistrationCatSuppliers',
      component: () => import('../../views/company/supplier_registration/CatSuppliersProgress.vue')
    },
    {
      path: '/company/supplier-registration/question/upload/progress/:supplier_registration_id/:task_id',
      name: 'CompanySupplierRegistrationQuestionUploadProgress',
      component: () => import('../../views/company/supplier_registration/QuestionUploadProgress.vue')
    },
    {
      path: '/company/supplier-registration/create/section/:supplier_registration_id/:category_id',
      name: 'CompanySupplierRegistration_create_section',
      component: () => import('../../views/company/supplier_registration/category/section/Create.vue')
    },
    {
      path: '/company/supplier-registration/edit/section/:supplier_registration_id/:category_id/:section_id',
      name: 'CompanySupplierRegistration_edit_section',
      component: () => import('../../views/company/supplier_registration/category/section/Edit.vue')
    },
    {
      path: '/company/supplier-registration/section/questions/:category_id/:section_id',
      name: 'CompanySupplierRegistration_section_questions',
      component: () => import('../../views/company/supplier_registration/category/section/Questions.vue')
    },
    {
      path: '/company/supplier-registration/create/question/:category_id/:section_id',
      name: 'CompanySupplierRegistration_create_question',
      component: () => import('../../views/company/supplier_registration/category/section/CreateQuestion.vue')
    },
    {
      path: '/company/supplier-registration/edit/question/:category_id/:section_id/:id',
      name: 'CompanySupplierRegistration_edit_question',
      component: () => import('../../views/company/supplier_registration/category/section/EditQuestion.vue')
    },
    {
      path: '/company/supplier-registration/qa/instructions/:category_id',
      name: 'CompanySupplierRegistration_qa_instructions',
      component: () => import('../../views/company/supplier_registration/category/qa/AddInstructions.vue')
    },
    {
      path: '/company/supplier-registration/conduct/qa/:category_id/:participant_id',
      name: 'CompanySupplierRegistration_conduct_qa',
      component: () => import('../../views/company/supplier_registration/category/qa/ConductQa.vue')
    },
    {
      path: '/company/supplier-registration/reports/:id',
      name: 'CompanySupplierRegistration_reports',
      component: () => import('../../views/company/supplier_registration/reports/Details.vue')
    },
    {
      path: '/company/supplier-registration/report/progress/:supplier_registration_id/:task_id',
      name: 'CompanySupplierRegistration_reports',
      component: () => import('../../views/company/supplier_registration/reports/Progress.vue')
    },
]

export default routes;