const routes = [
    {
        path: '/company/forward/auction/list',
        name: 'CompanyForwardAuction_list',
        component: () => import('../../views/company/auction/auctions/List.vue')
    },
    {
        path: '/company/forward/auction/create',
        name: 'CompanyForwardAuction_create',
        component: () => import('../../views/company/auction/auctions/Create.vue')
    },
    {
        path: '/company/reverse/auction/list',
        name: 'CompanyReverseAuction_list',
        component: () => import('../../views/company/auction/reverse_auctions/List.vue')
    },
    {
        path: '/company/foward/auction/details/:id',
        name: 'CompanyAuction_details',
        component: () => import('../../views/company/auction/auctions/Details.vue')
    },
    {
        path: '/company/auction/create/item/:auction_id',
        name: 'CompanyAuction_create_item',
        component: () => import('../../views/company/auction/auctions/item/Create.vue')
    },
    {
        path: '/company/auction/item/details/:auction_id/:item_id',
        name: 'CompanyAuction_item_details',
        component: () => import('../../views/company/auction/auctions/item/Details.vue')
    },
    {
        path: '/company/auction/edit/item/:auction_id/:item_id',
        name: 'CompanyAuction_edit_item',
        component: () => import('../../views/company/auction/auctions/item/Edit.vue')
    },
    {
        path: '/company/auction/invited/suppliers/:auction_id',
        name: 'CompanyAuction_invited_suppliers',
        component: () => import('../../views/company/auction/auctions/InvitedSuppliers.vue')
    },
]

export default routes;