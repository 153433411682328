import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/user/users',
        name: 'QedUserManagementUsers',
        component: () => import('../../views/qed/system_management/users/Users.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/users/create',
        name: 'QedUserManagementUsersCreate',
        component: () => import('../../views/qed/system_management/users/CreateUser.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/users/:id/update',
        name: 'QedUserManagementUsersUpdate',
        component: () => import('../../views/qed/system_management/users/UpdateUser.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/roles',
        name: 'QedUserManagementRoles',
        component: () => import('../../views/qed/system_management/roles/Roles.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/roles/create',
        name: 'QedUserManagementRolesCreate',
        component: () => import('../../views/qed/system_management/roles/CreateRole.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/roles/:id/update',
        name: 'QedUserManagementRolesUpdate',
        component: () => import('../../views/qed/system_management/roles/UpdateRole.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/roles/:id/privileges',
        name: 'QedUserManagementRolesPrivileges',
        component: () => import('../../views/qed/system_management/roles/Privileges.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_USER')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
      },
      {
        path: '/tendersure/user/logs',
        name: 'QedUserManagementLogs',
        component: () => import('../../views/qed/system_management/logs/Logs.vue')
      },
]

export default routes;