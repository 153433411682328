
import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/archive/buyers',
        name: 'BWQedArchiveBuyers',
        component: () => import('../../views/qed/archive/Companies.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }        
    },
    {
        path: '/tendersure/archive/buyers/:buyerId/jobs',
        name: 'BWQedArchiveJobs',
        component: () => import('../../views/qed/archive/Jobs.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/archive/jobs/:jobId/:sourcing_activity/categories',
        name: 'BWQedArchiveJobsCategories',
        component: () => import('../../views/qed/archive/Categories.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/archive/jobs/:jobId/:sourcing_activity/categories/:categoryId/suppliers',
        name: 'BWQedArchiveJobsCategorySuppliers',
        component: () => import('../../views/qed/archive/Suppliers.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/archive/:sourcing_activity/categories/:categoryId/suppliers/:supplierId',
        name: 'BWQedArchiveSupplierDocuments',
        component: () => import('../../views/qed/archive/SupplierDocuments.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
]

export default routes;