const initialState = () => ({
    selectedBranch: null,
});

const state = initialState();

const getters = {
    selectedBranch (state) {
        return state.selectedBranch
    },
}

const mutations = {
    updateSelectedBranch (state, payload) {
        state.selectedBranch = payload.branch
    },
}

const actions = {
    setSelectedBranch ({ commit }, payload) {
        commit('updateSelectedBranch', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
