const initialState = () => ({
    selectedBranch: null,
    selectedBranchDetails: null
});

const state = initialState();

const getters = {
    selectedBranch (state) {
        return state.selectedBranch
    },
    selectedBranchDetails (state) {
        return state.selectedBranchDetails
    },
}

const mutations = {
    updateSelectedBranch (state, payload) {
        state.selectedBranch = payload.branchID
    },
    updateSelectedBranchDetails (state, payload) {
        state.selectedBranchDetails = payload.branchDetails
    },
}

const actions = {
    setSelectedBranch ({ commit }, payload) {
        commit('updateSelectedBranch', payload)
    },
    setSelectedBranchDetails ({ commit }, payload) {
        commit('updateSelectedBranchDetails', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
