const routes = [
    {
        path: '/company/risk_management/dashboard',
        name: 'CompanyRiskManagementDashboard',
        component: () => import('../../views/company/risk_management/dashboard/Dashboard.vue')
    },
    {
        path: '/company/risk_management/list',
        name: 'CompanyRiskManagement_list',
        component: () => import('../../views/company/risk_management/List.vue')
    },
    {
        path: '/company/risk_management/details/:id',
        name: 'CompanyRiskManagement_job_details',
        component: () => import('../../views/company/risk_management/Details.vue')
    },
    {
        path: '/company/risk_management/create/job',
        name: 'CompanyRiskManagement_create_job',
        component: () => import('../../views/company/risk_management/Create.vue')
    },
    {
        path: '/company/edit/risk_management/:id',
        name: 'CompanyRiskManagement_edit_job',
        component: () => import('../../views/company/risk_management/Edit.vue')
    },
    {
        path: '/company/risk_management/create/category/:id',
        name: 'CompanyRiskManagement_category_create',
        component: () => import('../../views/company/risk_management/category/Create.vue')
    },
    {
        path: '/company/risk_management/category/details/:risk_id/:category_id',
        name: 'CompanyRiskManagement_category_details',
        component: () => import('../../views/company/risk_management/category/Details.vue')
    },
    {
        path: '/company/risk_management/edit/category/:risk_id/:category_id',
        name: 'CompanyRiskManagement_category_edit',
        component: () => import('../../views/company/risk_management/category/Edit.vue')
    },
    {
        path: '/company/risk_management/duplicate/category/:risk_id/:category_id',
        name: 'CompanyRiskManagementDuplicateCategory',
        component: () => import('../../views/company/risk_management/CloneCategory.vue')
    },
    {
        path: '/company/risk_management/create/section/:risk_id/:category_id',
        name: 'CompanyRiskManagement_create_section',
        component: () => import('../../views/company/risk_management/category/section/Create.vue')
    },
    {
        path: '/company/risk_management/section/questions/:category_id/:section_id',
        name: 'CompanyRiskManagement_section_questions',
        component: () => import('../../views/company/risk_management/category/section/Questions.vue')
    },
    {
        path: '/company/risk_management/edit/section/:risk_id/:category_id/:section_id',
        name: 'CompanyRiskManagement_edit_section',
        component: () => import('../../views/company/risk_management/category/section/Edit.vue')
    },
    {
        path: '/company/risk_management/create/question/:category_id/:section_id',
        name: 'CompanyRiskManagement_create_question',
        component: () => import('../../views/company/risk_management/category/section/CreateQuestion.vue')
    },
    {
        path: '/company/risk_management/edit/question/:category_id/:section_id/:id',
        name: 'CompanyRiskManagement_edit_question',
        component: () => import('../../views/company/risk_management/category/section/EditQuestion.vue')
    },
    {
        path: '/company/risk_management/invited/suppliers/:risk_id/:category_id',
        name: 'CompanyRiskManagement_invited_suppliers',
        component: () => import('../../views/company/risk_management/category/InvitedSuppliers.vue')
    },
    {
        path: '/company/risk_management/invite/system/suppliers/:risk_id/:category_id',
        name: 'CompanyRiskManagementInviteSystemSuppliers',
        component: () => import('../../views/company/risk_management/category/SystemSuppliers.vue')
    },
    {
        path: '/company/risk_management/qa/instructions/:category_id',
        name: 'CompanyRiskManagement_qa_instructions',
        component: () => import('../../views/company/risk_management/category/qa/AddInstructions.vue')
    },
    {
        path: '/company/risk_management/conduct/qa/:category_id/:participant_id',
        name: 'CompanyRiskManagement_conduct_qa',
        component: () => import('../../views/company/risk_management/category/qa/ConductQa.vue')
    },
    {
        path: '/company/risk_management/dd/details/:category_id',
        name: 'CompanyRiskManagement_dd_details',
        component: () => import('../../views/company/risk_management/category/dd/Participants.vue')
    },
    {
        path: '/company/risk_management/reports/:id',
        name: 'CompanyRiskManagement_reports',
        component: () => import('../../views/company/risk_management/reports/Details.vue')
    },
    {
        path: '/company/risk_management/report/progress/:risk_id/:task_id',
        name: 'CompanyRiskManagement_reports',
        component: () => import('../../views/company/risk_management/reports/Progress.vue')
    },

]
export default routes;
