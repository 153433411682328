import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/project_management/create_gantt_chart',
        name: 'QedProjectManagementGanttChartCreate',
        component: () => import('../../views/qed/project_management/timeline/CreateGanttChart.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/project_management/approved_gantt_chart',
        name: 'QedProjectManagementGanttChartApproved',
        component: () => import('../../views/qed/project_management/timeline/ApprovedGanttChart.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/project_management/project_gantt_chart',
        name: 'QedProjectManagementGanttChartProject',
        component: () => import('../../views/qed/project_management/timeline/ProjectGanttChart.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/project_management/meetings',
        name: 'QedProjectManagementMeetings',
        component: () => import('../../views/qed/project_management/meetings/Meetings.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/project_management/create_meetings',
        name: 'QedProjectManagementMeetingsCreate',
        component: () => import('../../views/qed/project_management/meetings/CreateMeetings.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
        path: '/tendersure/project_management/meetings/:id/update',
        name: 'QedProjectManagementMeetingsUpdate',
        component: () => import('../../views/qed/project_management/meetings/UpdateMeetings.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
]

export default routes;