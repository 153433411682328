import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/rfq/buyers',
        name: 'QedSourcingRfqBuyers',
        component: () => import('../../views/qed/rfq/Buyers.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    {
        path: '/tendersure/rfqs',
        name: 'QedSourcingRfqs',
        component: () => import('../../views/qed/rfq/List.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    // create RFQ
    {
        path: '/tendersure/create/rfq',
        name: 'QedSourcingRfqCreate',
        component: () => import('../../views/qed/rfq/Create.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    // RFQ Details
    {
        path: '/tendersure/rfq/details/:id',
        name: 'QedSourcingRfqDetails',
        component: () => import('../../views/qed/rfq/Details.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    // create RFQ category
    {
        path: '/tendersure/rfq/create/category/:id',
        name: 'QedSourcingRfqCategoryCreate',
        component: () => import('../../views/qed/rfq/category/Create.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    // RFQ category details
    {
        path: '/tendersure/rfq/category/details/:rfq_id/:category_id',
        name: 'QedSourcingRfqCategoryDetails',
        component: () => import('../../views/qed/rfq/category/Details.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    // RFQ edit category
    {
        path: '/tendersure/rfq/edit/category/:rfq_id/:category_id',
        name: 'QedSourcingRfqCategoryEdit',
        component: () => import('../../views/qed/rfq/category/Edit.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    //RFQ Invited suppliers
    {
      path: '/tendersure/rfq/invited/suppliers/:rfq_id/:category_id',
      name: 'QedSourcingRfqInvitedSupplier',
      component: () => import('../../views/qed/rfq/category/invitees/Invitees.vue')
    },
    //RFQ Invite bidders from prequal
    {
      path: '/tendersure/rfq/invite/from/prequal/list/:rfq_id/:category_id',
      name: 'QedSourcingRfqInviteFromPrequalification',
      component: () => import('../../views/qed/rfq/category/invitees/Prequalifications.vue')
    },
    {
      path: '/tendersure/rfq/invite/from/prequal/category/list/:rfq_id/:category_id/:prequal_id',
      name: 'QedSourcingRfqInviteFromPrequalCategories',
      component: () => import('../../views/qed/rfq/category/invitees/PrequalCategories.vue')
    },
    {
      path: '/tendersure/rfq/invite/from/prequal/category/suppliers/:rfq_id/:category_id/:prequal_id/:p_cat_id',
      name: 'QedSourcingRfqInviteFromPrequalCategorySuppliers',
      component: () => import('../../views/qed/rfq/category/invitees/PrequalCategorySuppliers.vue')
    },
    //RFQ Job Reports
    {
        path: '/tendersure/rfq/job/reports/:rfq_id',
        name: 'QedSourcingRfqJobReports',
        component: () => import('../../views/qed/rfq/reports/List.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'VIEW_REPORT')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    {
        path: '/tendersure/rfq/report/progress/:prequal_id/:task_id',
        name: 'QedSourcingRfqProgress',
        component: () => import('../../views/qed/rfq/reports/Progress.vue'),
        beforeEnter: (to, from, next) => {
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'VIEW_REPORT')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' })
          }
        }
    },
    {
        path: '/tendersure/rfq/category/upload/progress/:category_id/:task_id',
        name: 'QedSourcingRfqCategoryUploadProgress',
        component: () => import('../../views/qed/rfq/category/UploadProgress.vue')
    },
    {
        path: '/tendersure/rfq/category/download/progress/:category_id/:task_id',
        name: 'QedSourcingRfqCategoryDownloadProgress',
        component: () => import('../../views/qed/rfq/category/DownloadProgress.vue')
    },
    {
        path: '/tendersure/rfq/cat/suppliers/progress/:tender_id/:task_id/',
        name: 'QedSourcingRfqCatSuppliers',
        component: () => import('../../views/qed/rfq/RFQCatSupplierProgress.vue')
    },
]

export default routes;
