import store from '../../store/index'

const routes = [
    {
        path: '/tendersure/emails/failed/record',
        name: 'TendersureRecordFailedEmails',
        component: () => import('../../views/qed/system_management/emails/Record.vue'),
        beforeEnter: (to, from, next) => {        
            let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_DOCUMENTS')
            if(obj === true) {
              next()
            } else {
              next({ name: 'permissions-error' })
            }
          },
      },
]

export default routes;