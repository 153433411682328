import store from '../../store/index'

const routes = [
  {
    path: '/tendersure/asset/buyers',
    name: 'QedSourcingAssetDisposalBuyers',
    component: () => import('../../views/qed/asset_disposal/Buyers.vue'),

    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/assets',
    name: 'QedSourcingAssetDisposal',
    component: () => import('../../views/qed/asset_disposal/List.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/create/asset',
    name: 'QedSourcingAssetDisposal_create',
    component: () => import('../../views/qed/asset_disposal/Create.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/edit/asset/:id',
    name: 'QedSourcingAssetDisposal_edit',
    component: () => import('../../views/qed/asset_disposal/Edit.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/details/:id',
    name: 'QedSourcingAssetDisposalDetails',
    component: () => import('../../views/qed/asset_disposal/Details.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/create/category/:id',
    name: 'QedSourcingAssetDisposal_category_create',
    component: () => import('../../views/qed/asset_disposal/category/Create.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/category/details/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposal_category_details',
    component: () => import('../../views/qed/asset_disposal/category/Details.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/duplicate/category/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposal_category_duplicate',
    component: () => import('../../views/qed/asset_disposal/CloneCategory.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/create/item/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposalCreateItem',
    component: () => import('../../views/qed/asset_disposal/category/CreateItem.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/edit/item/:asset_id/:category_id/:id',
    name: 'QedSourcingAssetDisposalEditItem',
    component: () => import('../../views/qed/asset_disposal/category/EditItem.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/category/technical/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposalTechnical',
    component: () => import('../../views/qed/asset_disposal/category/Technical.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },

  {
    path: '/tendersure/asset/create/section/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposalCreateSection',
    component: () => import('../../views/qed/asset_disposal/category/section/CreateSection.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/edit/section/:asset_id/:category_id',
    name: 'QedSourcingAssetDisposalEditSection',
    component: () => import('../../views/qed/asset_disposal/category/section/EditSection.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset/invited/suppliers/:asset_id/:category_id',
    name: 'QedAsset_invited_suppliers',
    component: () => import('../../views/qed/asset_disposal/category/InvitedSuppliers.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/assets/reports/:id',
    name: 'CompanyAsset_reports',
    component: () => import('../../views/qed/asset_disposal/reports/Details.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset_disposal/report/progress/:asset_id/:task_id',
    name: 'QedAsset_reports',
    component: () => import('../../views/qed/asset_disposal/reports/Progress.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
  {
    path: '/tendersure/asset_disposal/category-buyer-upload/progress/:asset_id/:task_id',
    name: 'QedAssetCategoryUploadProgress',
    component: () => import('../../views/qed/asset_disposal/CategorySupplierUploadProgress.vue'),
    beforeEnter: (to, from, next) => {
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_CATEGORY')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' })
      }
    }
  },
]

export default routes;
