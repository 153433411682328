const routes = [
  {
    path: '/tendersure/suppliers',
    name: 'SwQedSupplierSystemRegisteredSuppliers',
    component: () => import('../../views/qed/dashboard/suppliers/Suppliers.vue'),
  },
  {
    path: '/tendersure/suppliers/categories',
    name: 'SwQedSuppliersCategories',
    component: () => import('../../views/qed/dashboard/suppliers/Categories.vue'),
  },
  {
    path: '/tendersure/suppliers/subscribed_suppliers',
    name: 'SwQedSuppliersSubscribed',
    component: () => import('../../views/qed/dashboard/suppliers/SubscribedSuppliers.vue'),
  },
  {
    path: '/tendersure/run_registered_suppliers/list',
    name: 'SwQedSuppliersRunList',
    component: () => import('../../views/qed/dashboard/suppliers/DailyRegistration.vue'),
  },
  {
    path: '/tendersure/suppliers/payment_reconciliation',
    name: 'SwQedSuppliersPaymentReconciliation',
    component: () => import('../../views/qed/payment_reconciliation/PendingPayments.vue'),
  },
  {
    path: '/tendersure/suppliers/payment_reconciliation/reconcile/:code',
    name: 'SwQedSuppliersPaymentReconciliationReconcile',
    component: () => import('../../views/qed/payment_reconciliation/Reconcile.vue'),
  },
  {
    path: '/tendersure/suppliers/payment_reconciliations',
    name: 'SwQedSuppliersPaymentReconciliations',
    component: () => import('../../views/qed/payment_reconciliation/AllReconciliations.vue'),
  },
  {
    path: '/tendersure/suppliers/payment_reconciliations/view/:code',
    name: 'SwQedSuppliersPaymentReconciliationReconcile',
    component: () => import('../../views/qed/payment_reconciliation/ReconciliationDetails.vue'),
  },

]

export default routes;
