const routes = [
    {
        path: '/company/profile',
        name: 'CompanyProfile',
        component: () => import('../../views/company/profile/CompanyProfile.vue')
    },
    {
        path: '/company/profile/update',
        name: 'CompanyProfileUpdate',
        component: () => import('../../views/company/profile/UpdateCompanyProfile.vue')
    },
]

export default routes;