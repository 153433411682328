const routes = [
    // Asset_Disposal Buyer Dashboard
  {
    path: '/company/asset_disposal/list',
    name: 'CompanyAssetDisposal_list',
    component: () => import('../../views/company/asset_disposal/listassets.vue')
  },
  {
    path: '/company/asset_disposal/create',
    name: 'CompanyAssetDisposal_create',
    component: () => import('../../views/company/asset_disposal/createassets.vue')
  },
  {
    path: '/company/asset_disposal/edit/:id',
    name: 'CompanyAssetDisposal_edit',
    component: () => import('../../views/company/asset_disposal/Edit.vue')
  },
  {
    path: '/company/asset_disposal/details/:id',
    name: 'CompanyAssetDisposal_list_category_details',
    component: () => import('../../views/company/asset_disposal/detailsassets.vue')
  },
  {
    path: '/company/asset_disposal/create/category/:id',
    name: 'CompanyAssetDisposal_create_category',
    component: () => import('../../views/company/asset_disposal/category/create.vue')
  },
  {
    path: '/company/asset_disposal/category/details/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_category_details',
    component: () => import('../../views/company/asset_disposal/category/details.vue')
  },

  {
    path: '/company/asset_disposal/category/details/technical/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_category_technicals',
    component: () => import('../../views/company/asset_disposal/category/technical.vue')
  },
  {
    path: '/company/asset_disposal/create/section/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_create_section',
    component: () => import('../../views/company/asset_disposal/category/section/create.vue')
  },
  {
    path: '/company/asset_disposal/edit/section/:asset_id/:category_id/:section_id',
    name: 'CompanyAssetDisposal_edit_section',
    component: () => import('../../views/company/asset_disposal/category/section/Edit.vue')
  },

  {
    path: '/company/asset_disposal/section/questions/:category_id/:section_id',
    name: 'CompanyAssetDisposal_section_questions',
    component: () => import('../../views/company/asset_disposal/category/section/Questions.vue')
  },
  {
    path: '/company/asset_disposal/create/question/:category_id/:section_id',
    name: 'CompanyAssetDisposal_create_question',
    component: () => import('../../views/company/asset_disposal/category/section/CreateQuestion.vue')
  },
  {
    path: '/company/asset_disposal/edit/question/:category_id/:section_id/:id',
    name: 'CompanyAssetDisposal_edit_question',
    component: () => import('../../views/company/asset_disposal/category/section/EditQuestion.vue')
  },
  {
    path: '/company/asset_disposal/edit/item/:asset_id/:category_id/:id',
    name: 'CompanyAssetDisposal_edit_item',
    component: () => import('../../views/company/asset_disposal/category/EditItem.vue')
  },
  {
    path: '/company/asset_disposal/create/item/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_create_item',
    component: () => import('../../views/company/asset_disposal/category/createItems.vue')
  },
  {
    path: '/company/asset_disposal/item/details/:asset_id/:category_id/:item_id',
    name: 'CompanyAssetDisposal_item_details',
    component: () => import('../../views/company/asset_disposal/category/itemdetails.vue')
  },
  {
    path: '/company/asset_disposal/reports/:id',
    name: 'CompanyAssetDisposal_reports_progress',
    component: () => import('../../views/company/asset_disposal/reports/Details.vue')
  },

  {
    path: '/company/asset_disposal/invited/suppliers/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_invited_suppliers',
    component: () => import('../../views/company/asset_disposal/category/InvitedSuppliers.vue')
  },

  {
    path: '/company/asset_disposal/invite/system/suppliers/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_invite_system_suppliers',
    component: () => import('../../views/company/asset_disposal/category/SystemSuppliers.vue')
  },
  {
    path: '/company/asset_disposal/report/progress/:asset_id/:task_id',
    name: 'CompanyAssetDisposal_reports',
    component: () => import('../../views/company/asset_disposal/reports/Progress.vue')
  },

  {
    path: '/company/asset_disposal/duplicate/category/:asset_id/:category_id',
    name: 'CompanyAssetDisposal_duplicate_category',
    component: () => import('../../views/company/asset_disposal/CloneCategory.vue')
  },
  {
    path: '/company/asset_disposal/question/upload/progress/:asset_id/:task_id',
    name: 'CompanyAssetDisposal_question_upload_progress',
    component: () => import('../../views/company/asset_disposal/QuestionUploadProgress.vue')
  },
  {
    path: '/company/asset_disposal/zip/files/progress/:task_id/:section_id',
    name: 'CompanyAssetDisposal_zip_files',
    component: () => import('../../views/company/asset_disposal/category/ZipFilesProgress.vue')
  },
]

export default routes;
