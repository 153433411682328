import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/tendersure/supplier-registration/buyers',
    name: 'QedSourcingSupplierRegistrationBuyers',
    component: () => import('../../views/qed/supplier_registration/Buyers.vue'),
    beforeEnter: (to, from, next) => {        
      let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
      if(obj === true) {
        next()
      } else {
        next({ name: 'permissions-error' }) 
      }
    }
  },
    {
      path: '/tendersure/supplier-registration',
      name: 'QedSupplierRegistration',
      component: () => import('../../views/qed/supplier_registration/List.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/edit/supplier-registration/:id',
      name: 'QedSupplierRegistrationEdit',
      component: () => import('../../views/qed/supplier_registration/Edit.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/create/supplier-registration',
      name: 'QedSupplierRegistrationCreate',
      component: () => import('../../views/qed/supplier_registration/Create.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/details/:id',
      name: 'QedSupplierRegistrationDetails',
      component: () => import('../../views/qed/supplier_registration/Details.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/create/category/:id',
      name: 'QedSupplierRegistrationCategoryCreate',
      component: () => import('../../views/qed/supplier_registration/category/Create.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/edit/category/:supplier_registration_id/:category_id',
      name: 'QedSupplierRegistrationCategoryEdit',
      component: () => import('../../views/qed/supplier_registration/category/Edit.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/category/details/:supplier_registration_id/:category_id',
      name: 'QedSupplierRegistrationCategoryDetails',
      component: () => import('../../views/qed/supplier_registration/category/Details.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/duplicate/category/:supplier_registration_id/:category_id',
      name: 'QedSupplierRegistrationDuplicateCategory',
      component: () => import('../../views/qed/supplier_registration/CloneCategory.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
        path: '/tendersure/supplier-registration/zip/files/progress/:task_id/:section_id',
        name: 'QedSupplierRegistrationZipFiles',
        component: () => import('../../views/qed/supplier_registration/category/ZipFilesProgress.vue'),
        beforeEnter: (to, from, next) => {        
          let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
          if(obj === true) {
            next()
          } else {
            next({ name: 'permissions-error' }) 
          }
        }
    },
    {
      path: '/tendersure/supplier-registration/cat/suppliers/progress/:supplier_registration_id/:task_id/',
      name: 'QedSupplierRegistrationCatSuppliers',
      component: () => import('../../views/qed/supplier_registration/CatSuppliersProgress.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/question/upload/progress/:supplier_registration_id/:task_id',
      name: 'QedSupplierRegistrationQuestionUploadProgress',
      component: () => import('../../views/qed/supplier_registration/QuestionUploadProgress.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/create/section/:supplier_registration_id/:category_id',
      name: 'QedSupplierRegistration_create_section',
      component: () => import('../../views/qed/supplier_registration/category/section/Create.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/edit/section/:supplier_registration_id/:category_id/:section_id',
      name: 'QedSupplierRegistration_edit_section',
      component: () => import('../../views/qed/supplier_registration/category/section/Edit.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/section/questions/:category_id/:section_id',
      name: 'QedSupplierRegistration_section_questions',
      component: () => import('../../views/qed/supplier_registration/category/section/Questions.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/create/question/:category_id/:section_id',
      name: 'QedSupplierRegistration_create_question',
      component: () => import('../../views/qed/supplier_registration/category/section/CreateQuestion.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/edit/question/:category_id/:section_id/:id',
      name: 'QedSupplierRegistration_edit_question',
      component: () => import('../../views/qed/supplier_registration/category/section/EditQuestion.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/qa/instructions/:category_id',
      name: 'QedSupplierRegistration_qa_instructions',
      component: () => import('../../views/qed/supplier_registration/category/qa/AddInstructions.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/conduct/qa/:category_id/:participant_id',
      name: 'QedSupplierRegistration_conduct_qa',
      component: () => import('../../views/qed/supplier_registration/category/qa/ConductQa.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/reports/:id',
      name: 'QedSupplierRegistration_reports',
      component: () => import('../../views/qed/supplier_registration/reports/Details.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
    {
      path: '/tendersure/supplier-registration/report/progress/:supplier_registration_id/:task_id',
      name: 'QedSupplierRegistration_reports',
      component: () => import('../../views/qed/supplier_registration/reports/Progress.vue'),
      beforeEnter: (to, from, next) => {        
        let obj = store.state.User.userData.user_type === 'qed' && store.state.Auth.privileges.some(privilege => privilege.title === 'MANAGE_JOB')
        if(obj === true) {
          next()
        } else {
          next({ name: 'permissions-error' }) 
        }
      }
    },
]

export default routes;